import {EStep} from '../bi/params';
import {appDefIds, envTypes, urlMap} from '../services/constants';
import {IEditorSDK} from '../types/wix-types';

export const setCurrentStepOnWorkerWithEditorSDK = (editorSDK: IEditorSDK) => async (
  step: EStep,
) => {
  const {setCurrentStep} = await editorSDK.editor.getAppAPI();
  setCurrentStep(step);
};

export const resolveAppUrl = (appDefId: string): string => {
  if (appDefId in appDefIds) {
    return urlMap[appDefIds[appDefId]];
  }
  return urlMap[envTypes.default];
};

export enum ModalResolveValues {
  CANCEL = 'CANCEL',
  COMPLETE = 'COMPLETE',
}
