import platformServices from '@wix/santa-platform-app-services';
const raven = require('raven-js');

const wrapWithMonitor = cb => async (...args) => {
  try {
    platformServices.monitoring.initSession(raven, 'https://6a7f5acdfec04196a9f3be0772e5613b@sentry.io/1279590');
    const monitor = platformServices.monitoring.createMonitor('https://6a7f5acdfec04196a9f3be0772e5613b@sentry.io/1279590');
    try {
      return await cb(...args);
    } catch (e) {
      monitor.captureException(e);
    }
  } catch (e) {
    // do nothing;
  }
};

export default wrapWithMonitor;
