import {biLogger} from './bi';
import {resolveAppUrl} from './utils/editorSDKhelper';
import wrapWithMonitor from './utils/monitor';

let _editorSDK;
let _token;
let currentStep;
let translation;
let appDefId;

export enum OnboardingEventType {
  openOnboarding = 'openOnboarding',
}

type OnboardingEvent = {
  eventType: OnboardingEventType,
};

const setCurrentStep = wrapWithMonitor(step => (currentStep = step));
const onEvent = wrapWithMonitor((event: OnboardingEvent) => {
  if (!event) {
    return;
  }
  switch (event.eventType) {
    case OnboardingEventType.openOnboarding:
      openOnboardingModal();
      break;
    default:
      break;
  }
});

const editorReady = wrapWithMonitor(async (editorSDK, token, {firstInstall}) => {
  const locale = await editorSDK.environment.getLocale();

  try {
    translation = require(`./assets/locale/onboarding_modal/messages_${locale}.json`);
  } catch (e) {
    translation = require(`./assets/locale/onboarding_modal/messages_en.json`);
  }

  _editorSDK = editorSDK;
  _token = token;
  appDefId = await editorSDK.info.getAppDefinitionId(token);
  const editorSessionId = await editorSDK.editor.info.getEditorSessionId();
  const metaSiteId = await editorSDK.document.info.getMetaSiteId();
  biLogger.util.updateDefaults({msid: metaSiteId, esi: editorSessionId});

  _editorSDK.editor.setAppAPI(token, {
    setCurrentStep
  });

  if (firstInstall) {
    await openOnboardingModal();
  }
});

async function openOnboardingModal() {
  const options = {
    url: resolveAppUrl(appDefId),
    initialData: {},
    width: '697',
    height: '644',
    title: translation.MULTILINGUAL_ONBOARDING_MODAL_TITLE
  };

  await
    _editorSDK.editor
      .openModalPanel(_token, {
        ...options,
        componentRef: {}
      })
      .then(val => {
        if (val === undefined) {
          biLogger.onboardingCancel({step: currentStep, finish_method: 'X'});
        }
      }).catch(e => {
      throw e;
    });
}

export {
  onEvent,
  setCurrentStep,
  editorReady
};
